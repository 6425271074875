import React from "react"
import { Link } from "gatsby"
import { detectIOS } from "@src/Helper"
import CustomBg from "@videos/pipehome-custom-bg.webm"
import CustomBgGIF from "@videos/pipehome-custom-bg.gif"

export const LandingCustomBackground = () => {
  return (
    <section className="landing__custom-background">
      <div className="container row mx-auto">
        <div className="col-6">
          <video
            src={CustomBg}
            className="w-100"
            playsInline
            autoPlay
            loop
            muted
          />
        </div>
        <div className="col-6 my-auto">
          <h2 className="landing__custom-background__title heading-4">
            <span className="text-apricot-60">
              <em>Tampilan</em>
            </span>{" "}
            suka-suka kamu
          </h2>
          <h3 className="landing__custom-background__description subhead">
            Kami paham selera setiap orang pasti berbeda, dan disini kamu bebas berekspresi sesuai branding dan selera desain kamu!
          </h3>
          <Link
            className="landing__custom-background__button button button-lg button-secondary"
            to="/register"
          >
            Buat Pipehome Sekarang!
          </Link>
        </div>
      </div>
    </section>
  )
}

export const LandingCustomBackgroundMobile = () => {
  return (
    <section className="landing__custom-background">
      <div className="container">
        <div className="row">
          <div className="col-12">
          {
            detectIOS() ?
            <img src={CustomBgGIF} alt="" className="w-100 h-100" /> :
            <video
              src={CustomBg}
              className="w-100"
              playsInline
              autoPlay
              loop
              muted
            />
          }
          </div>
          <div className="col-12">
            <h2 className="landing__custom-background__title heading-3">
              <span className="text-apricot-60">
                <em>Tampilan</em>
              </span>{" "}
              suka-suka kamu
            </h2>
            <h3 className="landing__custom-background__description subhead">
              Kami paham selera setiap orang pasti berbeda, dan disini kamu bebas berekspresi sesuai branding dan selera desain kamu!
            </h3>
            <Link
              className="landing__custom-background__button button button-lg button-secondary"
              to="/register"
            >
              Buat Pipehome Sekarang!
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
