import React from "react"
import Analytic from "@images/landing/analytic.svg"
import Display from "@images/landing/display.svg"
import Easytouse from "@images/landing/easytouse.svg"
import Itsfree from "@images/landing/itsfree.svg"
import Uptodate from "@images/landing/uptodate.svg"

export const LandingBenefits = () => {
  return (
    <section className="landing__benefits">
      <div className="container">
        <h2 className="landing__benefits__title heading-3">
          Kenapa harus <span className="text-apricot-60">SOCIOPIPE</span>?
        </h2>
        <div className="landing__benefits__list row">
          <div className="landing__benefits__list__item col-4">
            <img
              className="landing__benefits__list__item__image"
              src={Itsfree}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              It's Free
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Kami memiliki misi untuk membantu pemilik brand agar dapat lebih
              fokus untuk mempromosikan brandnya.
            </h4>
          </div>
          <div className="landing__benefits__list__item col-4">
            <img
              className="landing__benefits__list__item__image"
              src={Easytouse}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              Mudah digunakan
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Cukup daftar, Masukkan Link & Photo, Siap deh!
            </h4>
          </div>
          <div className="landing__benefits__list__item col-4">
            <img
              className="landing__benefits__list__item__image"
              src={Analytic}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              Tersedia Data Analitik
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Kami menyediakan <i>insight</i> & data analitik untuk membantu
              mengembangkan brand kamu.
            </h4>
          </div>
          <div className="landing__benefits__list__item col-4">
            <img
              className="landing__benefits__list__item__image"
              src={Display}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              Tampilan Sesuai Selera
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Mengubah tampilan website kamu juga bukan hal yang sulit disini.
              Berbagai tema sudah kami sediakan untuk kamu pilih!
            </h4>
          </div>
          <div className="landing__benefits__list__item col-4">
            <img
              className="landing__benefits__list__item__image"
              src={Uptodate}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              Selalu Up-to-date
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Kami berkomitmen untuk mengembangkan berbagai fitur yang akan terus
              membantu kamu sebagai <i>brand owners & creators</i>.
            </h4>
          </div>
        </div>
      </div>
    </section>
  )
}

export const LandingBenefitsMobile = () => {
  return (
    <section className="landing__benefits">
      <div className="container">
        <h2 className="landing__benefits__title heading-3">
          Kenapa harus <span className="text-apricot-60">SOCIOPIPE</span>?
        </h2>
        <div className="landing__benefits__list row">
          <div className="landing__benefits__list__item col-12">
            <img
              className="landing__benefits__list__item__image"
              src={Itsfree}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              It's Free
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Kami memiliki misi untuk membantu pemilik brand agar dapat lebih
              fokus untuk mempromosikan brandnya.
            </h4>
          </div>
          <div className="landing__benefits__list__item col-12">
            <img
              className="landing__benefits__list__item__image"
              src={Easytouse}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              Mudah digunakan
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Cukup daftar, Masukkan Link & Photo, Siap deh!
            </h4>
          </div>
          <div className="landing__benefits__list__item col-12">
            <img
              className="landing__benefits__list__item__image"
              src={Analytic}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              Tersedia Data Analitik
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Kami menyediakan <i>insight</i> & data analitik untuk membantu
              mengembangkan brand kamu.
            </h4>
          </div>
          <div className="landing__benefits__list__item col-12">
            <img
              className="landing__benefits__list__item__image"
              src={Display}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              Tampilan Sesuai Selera
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Mengubah tampilan website kamu juga bukan hal yang sulit disini.
              Berbagai tema sudah kami sediakan untuk kamu pilih!
            </h4>
          </div>
          <div className="landing__benefits__list__item col-12">
            <img
              className="landing__benefits__list__item__image"
              src={Uptodate}
              alt="Landing Benefits"
              width={48}
              height={48}
            />
            <h3 className="landing__benefits__list__item__title heading-6">
              Selalu Up-to-date
            </h3>
            <h4 className="landing__benefits__list__item__description body">
              Kami berkomitmen untuk mengembangkan berbagai fitur yang akan terus
              membantu kamu sebagai <i>brand owners & creators</i>.
            </h4>
          </div>
        </div>
      </div>
    </section>
  )
}
