import React from "react"
import { Link } from "gatsby"
import TargetMarket from "@videos/pipehome-target-market.webm"
import TargetMarketGIF from "@videos/pipehome-target-market.gif"
import { detectIOS } from "@src/Helper"

export const LandingTargetMarket = () => {
  return (
    <section className="landing__target-market">
      <div className="container row mx-auto">
        <div className="col-6 my-auto">
          <h2 className="landing__target-market__title heading-4">
            <span className="text-apricot-60">
              <em>Cocok</em>
            </span>{" "}
            buat siapa aja
          </h2>
          <h3 className="landing__target-market__description subhead">
            Bukan cuma sekedar <i>share</i> produk, <i>share</i> video, artikel,
            website, sosial media beserta post-postnya sekarang udah makin mudah
            dengan <i>Pipehome</i>.
          </h3>
          <Link
            className="landing__target-market__button button button-lg button-secondary"
            to="/register"
          >
            Buat Pipehome Sekarang!
          </Link>
        </div>
        <div className="col-6">
          <video
            src={TargetMarket}
            className="w-100"
            playsInline
            autoPlay
            loop
            muted
          />
        </div>
      </div>
    </section>
  )
}

export const LandingTargetMarketMobile = () => {
  return (
    <section className="landing__target-market">
      <div className="container">
        <div className="row">
          <div className="col-12">
          {
            detectIOS() ?
            <img src={TargetMarketGIF} alt="" className="w-100 h-100" /> :
            <video
              src={TargetMarket}
              className="w-100"
              playsInline
              autoPlay
              loop
              muted
            />
          }
          </div>
          <div className="col-12">
            <h2 className="landing__target-market__title heading-3">
              <span className="text-apricot-60">
                <em>Cocok</em>
              </span>{" "}
              buat siapa aja
            </h2>
            <h3 className="landing__target-market__description subhead">
              Bukan cuma sekedar <i>share</i> produk, <i>share</i> video,
              artikel, website, sosial media beserta post-postnya sekarang udah
              makin mudah dengan <i>Pipehome</i>.
            </h3>
            <Link
              className="landing__target-market__button button button-lg button-secondary"
              to="/register"
            >
              Buat Pipehome Sekarang!
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
