import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { getToken } from "@http/client"

export const LandingGetstarted = () => {
  return (
    <section className="landing__get-started">
      <div className="container">
        <div className="landing__get-started__card">
          <div className="row flex-a-center">
            <div className="col-4">
              <StaticImage
                className="landing__get-started__card__image"
                src="../../images/landing/landing-get-started.png"
                alt="Landing Get Started"
                width={337}
                height={279}
              />
            </div>
            <div className="col-8">
              <h2 className="landing__get-started__card__title heading-4">
                Mulai SEKARANG!
              </h2>
              <h3 className="landing__get-started__card__description subhead">
                Tunggu apa lagi? Yuk buat pipehome kamu
              </h3>
              <Link
                className="landing__get-started__card__button button button-lg button-secondary"
                to={`${getToken() ? "/dashboard" : "/register"}`}
              >
                Mulai GRATIS!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const LandingGetstartedMobile = () => {
  return (
    <section className="landing__get-started">
      <div className="container">
        <div className="landing__get-started__card">
          <div className="row flex-a-center">
            <div className="col-12">
              <StaticImage
                className="landing__get-started__card__image"
                src="../../images/landing/landing-get-started.png"
                alt="Landing Get Started"
                placeholder="tracedSVG"
                layout="fullWidth"
              />
            </div>
            <div className="col-12">
              <h2 className="landing__get-started__card__title heading-4">
                Mulai SEKARANG!
              </h2>
              <h3 className="landing__get-started__card__description subhead">
                Tunggu apa lagi? Yuk buat pipehome kamu
              </h3>
              <Link
                className="landing__get-started__card__button button button-lg button-secondary"
                to={`${getToken() ? "/dashboard" : "/register"}`}
              >
                Mulai GRATIS!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
