import * as React from "react"
import Landing from "@components/Landing/Landing"
import Seo from "@components/seo"

const IndexPage = () => {
  return (
    <>
      <Seo title="Micro Website Builder" />
      <Landing />
    </>
  )
}

export default IndexPage
