import React from "react"
import { Link } from "gatsby"
import { detectIOS } from "@src/Helper"
import Analytics from "@videos/pipehome-analytics.webm"
import AnalyticsGIF from "@videos/pipehome-analytics.gif"

export const LandingAnalytics = () => {
  return (
    <section className="landing__analytics">
      <div className="container row mx-auto">
        <div className="col-6 my-auto">
          <h2 className="landing__analytics__title heading-4">
            Akses ke Data{" "}
            <span className="text-apricot-60">
              <em>Analitik</em>
            </span>{" "}
            Lengkap
          </h2>
          <h3 className="landing__analytics__description subhead">
            Manfaatkan data analitik yang kami sediakan untuk semakin mengenal audiens-mu dan kembangkan brand-mu berdasarkan data yang akurat dan terpercaya langsung dari Sociopipe.
          </h3>
          <Link
            className="landing__analytics__button button button-lg button-secondary"
            to="/register"
          >
            Buat Pipehome Sekarang!
          </Link>
        </div>
        <div className="col-6">
          {/* <img src={MultipleDevices} alt="Share Pipehome anywhere" className="mb-0" /> */}
          <video src={Analytics} className="w-100" playsInline autoPlay loop muted />
        </div>
      </div>
    </section>
  )
}

export const LandingAnalyticsMobile = () => {
  return (
    <section className="landing__analytics">
      <div className="container">
        <div className="row">
          <div className="col-12">
          {
            detectIOS() ?
            <img src={AnalyticsGIF} alt="" className="w-100 h-100" /> :
            <video src={Analytics} className="w-100 h-100" playsInline autoPlay loop muted />
          }
          </div>
          <div className="col-12">
            <h2 className="landing__analytics__title heading-3">
              Akses ke Data{" "}
              <span className="text-apricot-60">
                <em>Analitik</em>
              </span>{" "}
              Lengkap
            </h2>
            <h3 className="landing__analytics__description subhead">
              Manfaatkan data analitik yang kami sediakan untuk semakin mengenal audiens-mu dan kembangkan brand-mu berdasarkan data yang akurat dan terpercaya langsung dari Sociopipe.
            </h3>
            <Link
              className="landing__analytics__button button button-lg button-secondary"
              to="/our-features"
            >
              Apa itu Pipehome?
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
