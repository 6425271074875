import React, { useState } from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getToken } from "@http/client"
import WhatIsPipehome from "@videos/what-is-pipehome.webm"
import WhatIsPipehomeImage from "@videos/what-is-pipehome.png"
import { detectIOS } from "@src/Helper"

export const LandingShowcase = () => {
  // Hooks
  const [username, setUsername] = useState("username")

  // JSX
  return (
    <section className="landing__showcase">
      <div className="landing__showcase__bg__1" />
      <div className="landing__showcase__bg__2" />
      <div className="landing__showcase__bg" />
      <div className="container">
        <div className="row">
          <div className="col-5 my-auto">
            <h1 className="landing__showcase__title heading-2">
              <span className="text-apricot-60">
                Satu <em>link</em>
              </span>
              ,<br />
              <span className="text-black-90">
                share produk & konten jadi
                <br />
                lebih mudah
              </span>
            </h1>
            {!getToken() && (
              <form
                className="row landing__showcase__form"
                onSubmit={e => {
                  e.preventDefault()
                  if (username !== "") {
                    navigate(`register?username=${username}`)
                  }
                }}
              >
                <div className="col-10 d-flex">
                  <input
                    type="text"
                    className="form-control landing__showcase__form__input"
                    placeholder="pipeho.me/"
                    disabled
                  />
                  <input
                    type="text"
                    className="form-control landing__showcase__form__input"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    onClick={() => {
                      if (username === "username") setUsername("")
                    }}
                    onBlur={() => {
                      if (username === "") setUsername("username")
                    }}
                    autoFocus
                  />
                </div>
                <div className="col">
                  <button className="landing__showcase__button button button-secondary button-sm w-100">
                    <i className="mdi mdi-arrow-right mdi-18px"></i>
                  </button>
                </div>
              </form>
            )}
          </div>
          <div className="col-7" style={{ zIndex: "1" }}>
            <video
              src={WhatIsPipehome}
              className="w-100"
              playsInline
              autoPlay
              loop
              muted
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const LandingShowcaseMobile = () => {
  const [username, setUsername] = useState("username")
  return (
    <section className={`landing__showcase ${getToken() && "pad-t-30"}`}>
      <div className="landing__showcase__bg__1" />
      <div className="landing__showcase__bg__2" />
      <div className={`landing__showcase__bg ${getToken() && "shorter"}`} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            {
              detectIOS() ?
              <img
                className="position-relative w-100"
                src={WhatIsPipehomeImage}
                alt="What Is Pipehome?"
              /> :
              <video
                src={WhatIsPipehome}
                className="position-relative w-100"
                playsInline
                autoPlay
                loop
                muted
              />
            }
          </div>
          <div className="col-12">
            <h1 className="landing__showcase__title heading-2 text-apricot-60">
              <span className="text-apricot-60">
                Satu <em>Link</em>
              </span>
              ,<br />
              <span className="text-black-90">
                share produk & konten jadi
                <br />
                lebih mudah
              </span>
            </h1>
            {!getToken() && (
              <form
                className="landing__showcase__form mb-5"
                onSubmit={e => {
                  e.preventDefault()
                  if (username !== "") {
                    navigate(`register?username=${username}`)
                  }
                }}
              >
                <div className="col-12 d-flex">
                  <input
                    type="text"
                    className="form-control landing__showcase__form__input"
                    placeholder="pipeho.me/"
                    disabled
                  />
                  <input
                    type="text"
                    className="form-control landing__showcase__form__input"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    onClick={() => {
                      if (username === "username") setUsername("")
                    }}
                    onBlur={() => {
                      if (username === "") setUsername("username")
                    }}
                    autoFocus
                  />
                </div>
                <div className="col mt-2">
                  <button className="landing__showcase__button button button-secondary button-sm w-100">
                    BUAT PIPEHOME
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
