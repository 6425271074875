import React from "react"
import { Link } from "gatsby"
import { detectIOS } from "@src/Helper"
import MultipleDevices from "@videos/pipehome-multiple-devices.webm"
import MultipleDevicesGIF from "@videos/pipehome-multiple-devices.gif"

export const LandingMultipleDevices = () => {
  return (
    <section className="landing__multiple-devices">
      <div className="container row mx-auto">
        <div className="col-6">
          {/* <img src={MultipleDevices} alt="Share Pipehome anywhere" className="mb-0" /> */}
          <video src={MultipleDevices} className="w-100" playsInline autoPlay loop muted />
        </div>
        <div className="col-6 my-auto">
          <h2 className="landing__multiple-devices__title heading-4">
            <span className="text-apricot-60">
              <em>Mudah</em>
            </span>{" "}
            diakses
          </h2>
          <h3 className="landing__multiple-devices__description subhead">
            Pipehome dapat diakses dari perangkat apa aja, dari laptop, tablet, smartphone hingga PC.<br />Jadi, cocok banget buat dijadikan micro website brand kamu.
          </h3>
          <Link
            className="landing__multiple-devices__button button button-lg button-secondary"
            to="/register"
          >
            Buat Pipehome Sekarang!
          </Link>
        </div>
      </div>
    </section>
  )
}

export const LandingMultipleDevicesMobile = () => {
  return (
    <section className="landing__multiple-devices">
      <div className="container">
        <div className="row">
          <div className="col-12">
          {
            detectIOS() ?
            <img src={MultipleDevicesGIF} alt="" className="w-100 h-100" /> :
            <video src={MultipleDevices} className="w-100 h-100" playsInline autoPlay loop muted />
          }
          </div>
          <div className="col-12">
            <h2 className="landing__multiple-devices__title heading-3">
              <span className="text-apricot-60">
                <em>Mudah</em>
              </span>{" "}
              diakses
            </h2>
            <h3 className="landing__multiple-devices__description subhead">
              Tampilan Pipehome sangat dioptimalkan sehingga cocok banget buat dijadikan <em>micro website</em> yang dapat diakses dari perangkat apa saja tanpa ada masalah.
            </h3>
            <Link
              className="landing__multiple-devices__button button button-lg button-secondary"
              to="/our-features"
            >
              Apa itu Pipehome?
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
