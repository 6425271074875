import React from "react"
import { detectMob } from "@src/Helper"
import { LandingBenefits, LandingBenefitsMobile } from "./LandingBenefits"
import { LandingConnection, LandingConnectionMobile } from "./LandingConnection"
import { LandingShowcase, LandingShowcaseMobile } from "./LandingShowcase"
import { LandingPromotion, LandingPromotionMobile } from "./LandingPromotion"
import { LandingGetstarted, LandingGetstartedMobile } from "./LandingGetstarted"
import { LandingCustomBackground, LandingCustomBackgroundMobile } from "./LandingCustomBackground"
import {
  LandingTargetMarket,
  LandingTargetMarketMobile,
} from "./LandingTargetMarket"
import {
  LandingMultipleDevices,
  LandingMultipleDevicesMobile,
} from "./LandingMultipleDevices"
import { LandingAnalytics, LandingAnalyticsMobile } from "./LandingAnalytics"

const Landing = () => {
  if (detectMob()) {
    return (
      <div id="m-landing" className="landing">
        <LandingShowcaseMobile />
        <LandingConnectionMobile />
        <LandingTargetMarketMobile />
        <LandingCustomBackgroundMobile />
        <LandingPromotionMobile />
        <LandingMultipleDevicesMobile />
        <LandingAnalyticsMobile />
        <LandingBenefitsMobile />
        <LandingGetstartedMobile />
      </div>
    )
  } else {
    return (
      <div className="landing">
        <div className="pad-t-100">
          <LandingShowcase />
          <LandingConnection />
          <LandingTargetMarket />
          <LandingCustomBackground />
          <LandingPromotion />
          <LandingMultipleDevices />
          <LandingAnalytics />
          <LandingBenefits />
          <LandingGetstarted />
        </div>
      </div>
    )
  }
}

export default Landing
