import React, { useRef } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

export const LandingPromotion = () => {
  // Hooks
  const slick = useRef()

  // Variable
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    centerPadding: "0px",
  }

  // JSX
  return (
    <section className="landing__promotion">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-6 offset-1">
            <h2 className="landing__promotion__title heading-4">
              Yuk, intip <em className="text-apricot-60">Pipehome</em> mereka
            </h2>
            <h3 className="landing__promotion__description subhead">
              Ini nih caranya mereka memaksimalkan fungsi dari <em>Pipehome</em>
              . Kalau kamu gimana?
            </h3>
            <Link
              className="landing__promotion__button button button-lg button-secondary"
              to="/register"
            >
              Buat Pipehome Sekarang!
            </Link>
          </div>
          <div className="col-4 offset-1 d-flex flex-column justify-content-center">
            <Slider {...settings} ref={slick}>
              <div style={{ width: 340 }}>
                <iframe
                  src={`${process.env.PIPEHOME_URL}/kompenicoffee?disable_analytic=1`}
                  className="mb-0"
                >
                  <p>
                    <a href="/en-US/docs/Glossary">
                      Fallback link for browsers that don't support iframes
                    </a>
                  </p>
                </iframe>
              </div>
              <div style={{ width: 340 }}>
                <iframe
                  src={`${process.env.PIPEHOME_URL}/martinmalavari?disable_analytic=1`}
                  className="mb-0"
                >
                  <p>
                    <a href="/en-US/docs/Glossary">
                      Fallback link for browsers that don't support iframes
                    </a>
                  </p>
                </iframe>
              </div>
              <div style={{ width: 340 }}>
                <iframe
                  src={`${process.env.PIPEHOME_URL}/vitaharvi?disable_analytic=1`}
                  className="mb-0"
                >
                  <p>
                    <a href="/en-US/docs/Glossary">
                      Fallback link for browsers that don't support iframes
                    </a>
                  </p>
                </iframe>
              </div>
            </Slider>
            <div className="landing__promotion__slick-button text-center">
              <i
                className="mdi mdi-arrow-left-bold mdi-36px cursor-pointer"
                onClick={() => {
                  slick.current.slickPrev()
                }}
              />
              <i
                className="mdi mdi-arrow-right-bold mdi-36px cursor-pointer"
                onClick={() => {
                  slick.current.slickNext()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const LandingPromotionMobile = () => {
  // Hooks
  const slick = useRef()

  // Variable
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    centerPadding: "0px",
  }

  // JSX
  return (
    <section className="landing__promotion">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Slider {...settings} ref={slick}>
              <div style={{ width: 340 }}>
                <iframe
                  src={`${process.env.PIPEHOME_URL}/kompenicoffee?disable_analytic=1`}
                  className="mb-0"
                >
                  <p>
                    <a href="/en-US/docs/Glossary">
                      Fallback link for browsers that don't support iframes
                    </a>
                  </p>
                </iframe>
              </div>
              <div style={{ width: 340 }}>
                <iframe
                  src={`${process.env.PIPEHOME_URL}/martinmalavari?disable_analytic=1`}
                  className="mb-0"
                >
                  <p>
                    <a href="/en-US/docs/Glossary">
                      Fallback link for browsers that don't support iframes
                    </a>
                  </p>
                </iframe>
              </div>
              <div style={{ width: 340 }}>
                <iframe
                  src={`${process.env.PIPEHOME_URL}/vitaharvi?disable_analytic=1`}
                  className="mb-0"
                >
                  <p>
                    <a href="/en-US/docs/Glossary">
                      Fallback link for browsers that don't support iframes
                    </a>
                  </p>
                </iframe>
              </div>
            </Slider>
            <div className="landing__promotion__slick-button text-center">
              <i
                className="mdi mdi-arrow-left-bold mdi-36px cursor-pointer"
                onClick={() => {
                  slick.current.slickPrev()
                }}
              />
              <i
                className="mdi mdi-arrow-right-bold mdi-36px cursor-pointer"
                onClick={() => {
                  slick.current.slickNext()
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <h2 className="landing__promotion__title heading-3">
              <i>
                <span className="text-apricot-60">Pemilik Bisnis</span>
              </i>
              ,{" "}
              <i>
                <span className="text-apricot-60">Content Creator</span>
              </i>{" "}
              hingga{" "}
              <i>
                <span className="text-apricot-60">Freelancer</span>
              </i>
            </h2>
            <h3 className="landing__promotion__description subhead">
              Pipehome bukan cuma sekedar website dan link in bio buat pemilik
              bisnis, tapi juga cocok banget buat para{" "}
              <strong>
                <i>Content Creator</i>
              </strong>{" "}
              dan{" "}
              <strong>
                <i>Freelancer</i>
              </strong>
            </h3>
            <Link
              className="landing__promotion__button button button-lg button-secondary"
              to="/register"
            >
              Buat Pipehome Sekarang!
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
