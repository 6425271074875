import React from "react"
import { Link } from "gatsby"
import { detectIOS } from "@src/Helper"
import ShareAnywhere from "@videos/share-pipehome-anywhere.webm"
import ShareAnywhereGIF from "@videos/share-pipehome-anywhere.gif"

export const LandingConnection = () => {
  return (
    <section className="landing__connection">
      <div className="container row mx-auto">
        <div className="col-6">
          <video
            src={ShareAnywhere}
            className="w-100"
            playsInline
            autoPlay
            loop
            muted
          />
        </div>
        <div className="col-6 my-auto">
          <h2 className="landing__connection__title heading-4">
            <span className="text-apricot-60">
              <em>Share</em>
            </span>{" "}
            Pipehome ke mana aja pasti mudah
          </h2>
          <h3 className="landing__connection__description subhead">
            Pipehome bukan cuma sekedar{" "}
            <strong>
              <i>micro website</i>
            </strong>
            , tapi juga bisa menjadi{" "}
            <strong>
              <i>link in bio</i>
            </strong>{" "}
            yang siap untuk di-<i>share</i> ke mana aja.
          </h3>
          <Link
            className="landing__connection__button button button-lg button-secondary"
            to="/our-features"
          >
            Apa itu Pipehome?
          </Link>
        </div>
      </div>
    </section>
  )
}

export const LandingConnectionMobile = () => {
  return (
    <section className="landing__connection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {
              detectIOS() ?
              <img src={ShareAnywhereGIF} alt="" className="w-100 h-100" /> :
              <video
                src={ShareAnywhere}
                className="w-100 h-100"
                playsInline
                autoPlay
                loop
                muted
              />
            }
          </div>
          <div className="col-12">
            <h2 className="landing__connection__title heading-3">
              <span className="text-apricot-60">
                <em>Share</em>
              </span>{" "}
              Pipehome ke mana aja pasti mudah
            </h2>
            <h3 className="landing__connection__description subhead">
              Pipehome bukan cuma sekedar <i>micro website</i>, tapi juga bisa
              menjadi <i>link in bio</i> yang siap untuk di-<i>share</i> ke mana
              aja.
            </h3>
            <Link
              className="landing__connection__button button button-lg button-secondary"
              to="/our-features"
            >
              Apa itu Pipehome?
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
